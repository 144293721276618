import { useEffect, useRef, useState } from "react";
import { Box } from "@chakra-ui/react";

import { Chart as ChartJS, PointElement, LineController, LineElement, TimeScale, LinearScale, Legend, Tooltip, TimeUnit, ChartData } from "chart.js";
import { Line } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";
import "chartjs-adapter-luxon"; // Adaptador para usar escalas de tiempo con zoomPlugin

import noDataPlugin from "./noDataPlugin";
import { ChamberData, NoDataPluginOptions } from "../../../utils/interfaces";
import { Mode } from "chartjs-plugin-zoom/types/options";
import { Tick } from "chart.js";

ChartJS.register(PointElement, LineController, LineElement, TimeScale, LinearScale, Legend, Tooltip);


const O2BackgroundColor = "#90CDF4";              // blue.200
const O2BorderColor = "#4299E1";                  // blue.400
const CO2BackgroundColor = "#FEB2B2";             // red.200
const CO2BorderColor = "#F56565";                 // red.400
const PulpTemperatureBackgroundColor = "#9DECF9"; // cyan.200
const PulpTemperatureBorderColor = "#0BC5EA";     // cyan.400
const AirTemperatureBackgroundColor = "#FBB6CE";  // pink.200
const AirTemperatureBorderColor = "#ED64A6";      // pink.400
const HumidityBackgroundColor = "#9AE6B4";        // green.200
const HumidityBorderColor = "#48BB78";            // green.400

const SECOND = 1_000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

const NULL_VAL = 999.0;

interface Bounds {
  min: number;
  max: number;
};

type TickCallback = (tickValue: number, index: number, tick: Tick[]) => number;

interface ChamberChartProps {
  localChamberID: number;
  chamberData: ChamberData;
};

/**
 * ChamberChartSection muestra todas las mediciones de la cámara del ID
 * indicado por el parámetro localChamberID. Estas mediciones están en
 * chamberData.
 * 
 * ChamberChartSection usa el plugin zoomPlugin para poder hacer zoom y pan por
 * todos los datos. Así, por defecto se muestran los últimos 3 días de datos,
 * pero se puede navegar por ellos.
 * 
 * Hay un "conflicto" entre react-chartjs-2 y chartjs-plugin-zoom que provoca
 * que, cada vez que cambien los datos en chamberData, se resetee el zoom a los
 * valores originales al re-renderizar el gráfico.
 * 
 * Para evitarlo, se guarda el estado actual del zoom en "bounds", un arreglo
 * con el mínimo y máximo valor a mostrar en el gráfico en el momento actual.
 * Así, cada vez que se re-renderice el gráfico, se reusa el estado anterior de
 * "bounds". Sin embargo, si cambia la ID de la cámara o si se actualizan los
 * datos, el zoom se resetea al valor por defecto. Cada vez que se hace zoom o
 * pan, se gatillan los eventos onZoom y onPan que guardan el nuevo estado en
 * "bounds".
 */
export default function ChamberChart({ localChamberID, chamberData }: ChamberChartProps): JSX.Element {
  const { unixTimes, o2, o2SetPointGroups, co2, co2SetPointGroups, airTemperatures, pulpTemperatures, humidities } = chamberData;

  function flattenGroups(source: number[][]): number[] {
    const result: number[] = [];
    source.forEach(group => {
      const [value, repeatTimes] = group;
      for (let i = 0; i < repeatTimes; i++) {
        result.push(value);
      }
    })
    return result;
  }

  const o2SetPoints = flattenGroups(o2SetPointGroups);
  const co2SetPoints = flattenGroups(co2SetPointGroups);

  const dataBounds = new Map<string, Bounds>();
  const tickCallbacks = new Map<string, TickCallback>();
  const categories = new Map<string, [number[][], Bounds]>()
    .set("gas", [[o2, co2, o2SetPoints, co2SetPoints], { min: 0, max: 25 }])
    .set("temperature", [[pulpTemperatures, airTemperatures], { min: -5, max: 30 }])
    .set("humidity", [[humidities], { min: 0, max: 100 }]);

  for (const [category, [attributes, attrBounds]] of categories) {
    const filteredArrs = attributes.map(attr =>
      attr.filter(val => val !== NULL_VAL)
    );
    const arrMins = filteredArrs.map(arr => Math.min(...arr));
    const arrMaxes = filteredArrs.map(arr => Math.max(...arr));
    const categoryMin = Math.min(attrBounds.min, ...arrMins);
    const categoryMax = Math.max(attrBounds.max, ...arrMaxes);

    tickCallbacks.set(category, (tickValue, index, ticks) => {
      if (tickValue < categoryMin) {
        tickValue = categoryMin;
        ticks[index].value = categoryMin;
        ticks[index].label = categoryMin.toString();
      }
      else if (tickValue > categoryMax) {
        tickValue = categoryMax;
        ticks[index].value = categoryMax;
        ticks[index].label = categoryMax.toString();
      }
      return tickValue;
    });

    const categorySize = categoryMax - categoryMin;

    dataBounds.set(category, {
      min: categoryMin - 0.04 * categorySize,
      max: categoryMax + 0.04 * categorySize,
    });
  }

  function getDataset(array: number[]) {
    return unixTimes.map((unixTime, i) => ({
      x: 1000 * unixTime,
      y: (array[i] === NULL_VAL) ? null : array[i],
    }));
  }

  const data: ChartData<"line"> = {
    datasets: [
      {
        label: "O₂",
        yAxisID: "yGas",
        data: getDataset(o2),
        backgroundColor: O2BackgroundColor,
        borderColor: O2BorderColor,
        borderWidth: 2.75,
        pointRadius: 4.5,
        pointHoverRadius: 6,
        pointBorderWidth: 1.75,
        pointHoverBorderWidth: 1.75,
      },
      {
        label: "CO₂",
        yAxisID: "yGas",
        data: getDataset(co2),
        backgroundColor: CO2BackgroundColor,
        borderColor: CO2BorderColor,
        borderWidth: 2.75,
        pointRadius: 4.5,
        pointHoverRadius: 6,
        pointBorderWidth: 1.75,
        pointHoverBorderWidth: 1.75,
      },
      {
        label: "SP O₂",
        yAxisID: "yGas",
        data: getDataset(o2SetPoints),
        backgroundColor: O2BackgroundColor,
        borderColor: O2BorderColor,
        borderWidth: 2,
        borderDash: [6, 4],
        pointRadius: 1,
        pointHoverRadius: 2,
        pointBorderWidth: 1,
        pointHoverBorderWidth: 1,
      },
      {
        label: "SP CO₂",
        yAxisID: "yGas",
        data: getDataset(co2SetPoints),
        backgroundColor: CO2BackgroundColor,
        borderColor: CO2BorderColor,
        borderWidth: 2,
        borderDash: [6, 4],
        pointRadius: 1,
        pointHoverRadius: 2,
        pointBorderWidth: 1,
        pointHoverBorderWidth: 1,
      },
      {
        label: "T° Pulpa",
        yAxisID: "yTemperature",
        data: getDataset(pulpTemperatures),
        backgroundColor: PulpTemperatureBackgroundColor,
        borderColor: PulpTemperatureBorderColor,
        borderWidth: 1,
        pointRadius: 3,
      },
      {
        label: "T° Aire",
        yAxisID: "yTemperature",
        data: getDataset(airTemperatures),
        backgroundColor: AirTemperatureBackgroundColor,
        borderColor: AirTemperatureBorderColor,
        borderWidth: 1,
        pointRadius: 3,
      },
      {
        label: "Humedad",
        yAxisID: "yHumidity",
        data: getDataset(humidities),
        backgroundColor: HumidityBackgroundColor,
        borderColor: HumidityBorderColor,
        borderWidth: 1,
        pointRadius: 3,
      },
    ]
  };

  return (
    <Box
      minWidth={0} // NECESARIO para que el gráfico se encoja al achicar la página
      w="full" // NECESARIO para que ocupe todo el ancho disponible
      h={{ "base": "200px", "sm": "240px", "md": "280px", "lg": "276px", "2xl": "360px" }}
      px={4}
      pt={2}
      pb={{ "base": 1, "md": 2 }}
    >
      <ChamberChartContent
        data={data}
        chamberData={chamberData}
        o2SetPoints={o2SetPoints}
        co2SetPoints={co2SetPoints}
        localChamberID={localChamberID}
        dataBounds={dataBounds}
        tickCallbacks={tickCallbacks}
      />
    </Box>
  );
}


interface ChamberChartContentProps {
  data: ChartData<"line">
  chamberData: ChamberData;
  o2SetPoints: number[];
  co2SetPoints: number[];
  localChamberID: number;
  dataBounds: Map<string, Bounds>;
  tickCallbacks: Map<string, TickCallback>;
}

function ChamberChartContent({ data, chamberData, o2SetPoints, co2SetPoints, localChamberID, dataBounds, tickCallbacks }: ChamberChartContentProps): JSX.Element {
  const { unixTimes } = chamberData;

  const isScreenLarge = (window.innerWidth >= 768);

  const LEN = unixTimes.length;
  const lastDate = (LEN >= 1) ? 1000 * unixTimes[LEN - 1] : 3 * DAY;
  const nextToLastDate = (LEN >= 2) ? 1000 * unixTimes[LEN - 2] : 3 * DAY - HOUR;

  const searchInsert = (array: number[], value: number): number => {
    let L = 0;
    let H = array.length - 1;
    while (L <= H) {
      const M = Math.floor((L + H) / 2);
      if (value === array[M]) {
        return M;
      }
      if (value < array[M]) {
        H = M - 1;
      } else {
        L = M + 1;
      }
    }
    return L;
  }

  const calculateBounds = (minTime: number, maxTime: number) => {
    const { unixTimes: times, o2, co2 } = chamberData;
    const minTimeIndex = searchInsert(times, minTime / 1000);
    const maxTimeIndex = searchInsert(times, maxTime / 1000);

    let startO2 = o2[minTimeIndex];
    let startCO2 = co2[minTimeIndex];
    if (minTimeIndex > 0) {
      const startTimeDiff = times[minTimeIndex] - times[minTimeIndex-1];
      if (startTimeDiff > 0) {
        const alpha = (minTime / 1000 - times[minTimeIndex-1]) / startTimeDiff;
        startO2 = (1-alpha) * o2[minTimeIndex-1] + alpha * o2[minTimeIndex];
        startCO2 = (1-alpha) * co2[minTimeIndex-1] + alpha * co2[minTimeIndex];
      }
    }
    
    let endO2 = o2[maxTimeIndex-1];
    let endCO2 = co2[maxTimeIndex-1];
    if (maxTimeIndex < times.length) {
      const endTimeDiff = times[maxTimeIndex] - times[maxTimeIndex-1];
      if (endTimeDiff > 0) {
        const alpha = (maxTime / 1000 - times[maxTimeIndex-1]) / endTimeDiff;
        endO2 = (1-alpha) * o2[maxTimeIndex-1] + alpha * o2[maxTimeIndex];
        endCO2 = (1-alpha) * co2[maxTimeIndex-1] + alpha * co2[maxTimeIndex];
      }
    }

    const minGas = Math.min(
      o2SetPoints[o2SetPoints.length-1] - 3, ...o2.slice(minTimeIndex, maxTimeIndex),
      co2SetPoints[co2SetPoints.length-1] - 3, ...co2.slice(minTimeIndex, maxTimeIndex),
    ) - 1;
    const maxGas = Math.max(
      o2SetPoints[o2SetPoints.length-1] + 3, ...o2.slice(minTimeIndex, maxTimeIndex),
      co2SetPoints[co2SetPoints.length-1] + 3, ...co2.slice(minTimeIndex, maxTimeIndex),
    ) + 1;

    return {
      minTime: minTime,
      maxTime: maxTime,
      minGas: minGas,
      maxGas: maxGas,
    };
  };
  
  const [bounds, setBounds] = useState(calculateBounds(lastDate - 3 * DAY, lastDate + HOUR));
  const boundsRef = useRef(bounds); // necesario para referenciar a bounds en useEffect sin agregarlo en el arreglo de dependencias

  useEffect(() => {
    setBounds(calculateBounds(lastDate - 3 * DAY, lastDate + HOUR));
  }, [localChamberID, lastDate]);

  useEffect(() => {
    const bounds = boundsRef.current;
    if (bounds[0] === nextToLastDate - 3 * DAY && bounds[1] === nextToLastDate + HOUR) {
      setBounds(calculateBounds(lastDate - 3 * DAY, lastDate + HOUR));
    }
  }, [chamberData, lastDate, nextToLastDate]);

  const options = {
    // Parámetros para optimizar renderizado
    spanGaps: true,
    normalized: true,
    parsing: false as false,
    animation: false as false,

    // Parámetros para responsividad
    responsive: true,
    maintainAspectRatio: false,

    layout: {
      padding: { left: 4, right: 4, top: -2 },
    },

    // Opciones de ejes
    scales: {
      x: {
        type: "time" as "time",
        min: bounds.minTime,
        max: bounds.maxTime,
        time: {
          minUnit: "second" as TimeUnit,
          displayFormats: { hour: "H:MM" },
        },
        ticks: {
          major: { enabled: true },
          font(ctx: any) {
            if (ctx.tick && ctx.tick.major) {
              return { size: 14, weight: "bold" };
            }
          },
          minRotation: 0,
          maxRotation: 0,
        },
        grid: {
          color(ctx: any) {
            return ctx.tick.major ? "rgba(0, 0, 0, 0.2)" : "rgba(0, 0, 0, 0.1)";
          }
        }
      },
      yGas: {
        min: bounds.minGas,
        max: bounds.maxGas,
        title: {
          display: true,
          text: "Concentración de gas [%]",
          font: { size: isScreenLarge ? 16 : 12 },
          padding: isScreenLarge ? 3 : 1,
        },
        ticks: {
          font: { size: isScreenLarge ? 16 : 12 },
          padding: isScreenLarge ? 3 : 0,
          callback(tickValue: number, index: number, ticks: Tick[]) {
            const minGas = Math.ceil(bounds.minGas);
            const maxGas = Math.floor(bounds.maxGas);
            if (tickValue <= minGas) {
              tickValue = minGas;
              ticks[index].value = minGas;
              ticks[index].label = minGas.toString();
            }
            else if (tickValue >= maxGas) {
              tickValue = maxGas;
              ticks[index].value = maxGas;
              ticks[index].label = maxGas.toString();
            }
            return tickValue;
          },
        },
        grid: { display: false },
      },
      yTemperature: {
        min: dataBounds.get("temperature").min,
        max: dataBounds.get("temperature").max,
        title: {
          display: true,
          text: "Temperatura [°C]",
          font: { size: isScreenLarge ? 16 : 12 },
          padding: isScreenLarge ? 3 : 1,
        },
        ticks: {
          font: { size: isScreenLarge ? 16 : 12 },
          padding: isScreenLarge ? 3 : 0,
          callback: tickCallbacks.get("temperature"),
        },
        position: "right" as "right",
        grid: { display: false },
      },
      yHumidity: {
        min: dataBounds.get("humidity").min,
        max: dataBounds.get("humidity").max,
        title: {
          display: true,
          text: "Humedad [%]",
          font: { size: isScreenLarge ? 16 : 12 },
          padding: isScreenLarge ? 3 : 1,
        },
        ticks: {
          font: { size: isScreenLarge ? 16 : 12 },
          padding: isScreenLarge ? 3 : 0,
          callback: tickCallbacks.get("humidity"),
        },
        position: "right" as "right",
      },
    },

    // Opciones de plugins
    plugins: {
      legend: { labels: { boxWidth: isScreenLarge ? 32 : 24 } },
      tooltip: {
        callbacks: {
          label(tooltipItem: any) {
            if (tooltipItem.dataset.label === "T° Pulpa" || tooltipItem.dataset.label === "T° Aire") {
              return tooltipItem.dataset.label + ": " + parseFloat(tooltipItem.formattedValue.replace(",", ".")).toFixed(1).replace(".", ",") + "°C";
            } else {
              return tooltipItem.dataset.label + ": " + tooltipItem.formattedValue + "%";
            }
          }
        }
      },
      zoom: {
        // onZoom y onPan constantemente guardan los límites inferior y superior a mostrar del gráfico
        zoom: {
          wheel: { enabled: true },
          pinch: { enabled: true },
          mode: "x" as Mode,
          onZoom: (chart: { chart: ChartJS }) => {
            const x = chart.chart.scales.x;
            setBounds(calculateBounds(x.min, x.max));
          },
        },
        pan: {
          enabled: true,
          mode: "x" as Mode,
          onPan: (chart: { chart: ChartJS }) => {
            const x = chart.chart.scales.x;
            setBounds(calculateBounds(x.min, x.max));
          },
        },
        limits: {
          x: {
            min: (LEN > 0) ? 1000 * unixTimes[0] - HOUR : 0,
            max: (LEN > 0) ? 1000 * unixTimes[LEN - 1] + HOUR : 3 * DAY + HOUR,
          }
        }
      },
      noDataPlugin: { noData: (LEN === 0) } as NoDataPluginOptions,
    }
  };

  return (
    <Line
      data={data}
      plugins={[zoomPlugin, noDataPlugin]}
      options={options}
    />
  );
}