import { Table, Th, Td, TableContainer } from "@chakra-ui/react";

import "./tinyTable.css";
import { RecentData } from "../../../../utils/interfaces";


const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

interface ChamberTableProps {
  recentData: RecentData;
  index: number;
  lastSetPointChangeDate: Date | null;
}

/**
 * ChamberTable muestra los datos de las últimas mediciones asociadas a la
 * cámara, guardadas en la variable tableData pasada como parámetro.
 */
export default function ChamberTable({ recentData, index, lastSetPointChangeDate }: ChamberTableProps): JSX.Element {
  let lastSetPointChangeDateString = "-";
  
  const D = lastSetPointChangeDate;
  if (D) {
    const day = D.getDate().toString().padStart(2, "0");
    const month = MONTHS[D.getMonth()];
    const time = D.toTimeString().slice(0, 8);
    lastSetPointChangeDateString = `${day}-${month} ${time}`;
  }

  return (
    <TableContainer borderRadius="md">
      <Table
        className="tiny" // tinyTable.css modifica la tabla usando este className
        size={{"base": "sm", "md": "md", "lg": "sm", "2xl": "md"}}
      >
        <thead>
          <tr>
            <Th textAlign="center" bg="gray.400"></Th>
            <Th textAlign="center" bg="red.300">CO₂ %</Th>
            <Th textAlign="center" bg="blue.300">O₂ %</Th>
            <Th textAlign="center" bg="gray.300">Fecha</Th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td bg="gray.300">Set point</Td>
            <Td isNumeric bg="red.100">{recentData.co2SetPoints[index].toFixed(1)}</Td>
            <Td isNumeric bg="blue.100">{recentData.o2SetPoints[index].toFixed(1)}</Td>
            <Td bg="gray.100">{lastSetPointChangeDateString}</Td>
          </tr>
          <tr>
            <Td bg="gray.300">Últ. medición</Td>
            <Td isNumeric bg="red.100">{recentData.lastMeasuredCO2[index].toFixed(1)}</Td>
            <Td isNumeric bg="blue.100">{recentData.lastMeasuredO2[index].toFixed(1)}</Td>
            <Td bg="gray.100">{recentData.lastMeasuredDates[index]}</Td>
          </tr>
          <tr>
            <Td bg="gray.300">Últ. alcanzado</Td>
            <Td isNumeric bg="red.100">{recentData.lastReachedCO2[index].toFixed(1)}</Td>
            <Td isNumeric bg="blue.100">{recentData.lastReachedO2[index].toFixed(1)}</Td>
            <Td bg="gray.100">{recentData.lastReachedDates[index]}</Td>
          </tr>
        </tbody>
      </Table>
    </TableContainer>
  );
}