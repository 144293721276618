import { useState, useEffect } from "react";

import { Box } from "@chakra-ui/react";

import ChamberInfo from "./chamberInfo/ChamberInfo";

import { getChamberData } from "../../utils/api";
import { ChamberData, RecentData } from "../../utils/interfaces";
import ChamberChart from "./chamberChart/ChamberChart";
import { EMPTY_CHAMBER_DATA } from "../../utils/constants";


interface ChamberSectionProps {
  selectedUsername: string;
  globalChamberID: number;
  recentData: RecentData;
}

/**
 * ChamberSection contiene los datos específicos de la cámara con el ID
 * indicado.
 * 
 * Estructura:
 * - ChamberSection
 *   - ChamberChartSection
 *   - ChamberInfoSection
 *     - ChamberTable
 *     - DownloadButton
 * 
 * Tiene dos secciones importantes: un gráfico (ChamberChartSection) con todas
 * las mediciones de la cámara guardadas en un estado chartData, y una segunda
 * sección (ChamberInfoSection) que contiene el estado de la cámara y una tabla
 * (ChamberTable) que muestra sus datos más recientes de la cámara, todo ello
 * guardado en el estado recentData.
 * 
 * IMPORTANTE:
 * ChamberSection cambia cada vez que cambia la ID de la cámara, recibida como
 * parámetro (globalChamberID). Sin embargo, debido a que toma mucho tiempo
 * cargar desde el backend todos los datos en chartData, ocurre un desfase en
 * el que ChamberChartSection se carga mucho después que ChamberInfoSection.
 * Para resolver este problema, se creó un segundo estado localChamberID, que
 * cambia casi al mismo tiempo que se carga recentData. El funcionamiento es el
 * siguiente:
 * 
 * 1. Ocurre un cambio en globalChamberID
 * 2. Este cambio provoca que se pidan del backend todos los datos específicos
 *    de la cámara
 * 3. Una vez que estén disponibles, se settea casi al mismo tiempo chartData y
 *    localChamberID
 * 4. Con chartData se actualiza ChamberChartSection, y con localChamberID se
 *    actualiza ChamberInfoSection
 */
export default function ChamberSection({ selectedUsername, globalChamberID, recentData }: ChamberSectionProps): JSX.Element {
	const [chamberData, setChamberData] = useState<ChamberData>(EMPTY_CHAMBER_DATA); // array de JSONs
  const [localChamberID, setLocalChamberID] = useState(1);

  useEffect(() => {
    if (selectedUsername) {
      /*
      GET /:user/:id llama a la función afhttp.GETChartData en Golang,
      definida en atmosfix-app-api/afhttp/get_chart_data.go

      Existe un lag asociado con este GET, del cual se habla en la documentación
      de ChamberSection y que se compensa creando el estado localChamberID.
      */ 
      getChamberData(selectedUsername, globalChamberID)
      .then(res => setChamberData(res.data))
      .catch(_ => setChamberData(EMPTY_CHAMBER_DATA))
      .finally(() => setLocalChamberID(globalChamberID));
    }
    
  }, [selectedUsername, recentData, globalChamberID]);

  const { unixTimes, o2SetPointGroups, co2SetPointGroups } = chamberData;
  const numLogs = unixTimes.length;

  let lastSetPointChangeDate: Date | null = null;
  if (numLogs > 0) {
    const numO2Groups = o2SetPointGroups.length;
    const numCO2Groups = co2SetPointGroups.length;
    const numLogsWithoutChange = Math.min(
      o2SetPointGroups[numO2Groups-1][1],
      co2SetPointGroups[numCO2Groups-1][1],
    );
    const unixTimeOfLastChange = unixTimes[numLogs - numLogsWithoutChange]
    lastSetPointChangeDate = new Date(1000 * unixTimeOfLastChange);
  }

  const recentDataIndex = recentData.chamberIDs.findIndex(id => id === localChamberID);
  
	return (
    /*
    display controla cómo se disponen las cosas en esta sección.
    Por defecto, la tabla se muestra debajo del gráfico.
    Pero a partir del tamaño grande ("lg") Box se muestra como flex, que hace
    que la tabla aparezca a la derecha del gráfico, y el Flex se expande y usa
    todo el ancho de la pantalla.
    */
		<Box w="full" borderWidth="2px" borderRadius="lg" display={{"lg": "flex"}}>
      <ChamberChart localChamberID={localChamberID} chamberData={chamberData} />
      { recentDataIndex !== -1 &&
        <ChamberInfo
          selectedUsername={selectedUsername}
          localChamberID={localChamberID}
          recentData={recentData}
          index={recentDataIndex}
          lastSetPointChangeDate={lastSetPointChangeDate}
          chamberHasData={numLogs > 0}
        />
      }
		</Box>
	);
}